import { USER, GROUP, ORGANIZATION } from '../Constants';

export const isUser = type => (type && type.toUpperCase() === USER.toUpperCase());
export const isGroup = type => (type && type.toUpperCase() === GROUP.toUpperCase());
export const isOrganization = type => (type && type.toUpperCase() === ORGANIZATION.toUpperCase());

export const isUserPath = path => path && path.startsWith('/dashboard/private');
export const isGroupsPath = path => path && path.startsWith('/dashboard/groups');
export const isGroupPath = (path, groupId) => path && path.startsWith(`/dashboard/groups/${groupId}`);
export const isOrganizationPath = path => path && path.startsWith('/dashboard/organization');

export const getContentParentDashboardPath = (content) => {
  let path = '/dashboard';
  if (isUser(content.owner_type)) path += '/private';
  if (isGroup(content.owner_type)) path += `/groups/${content.owner_id}`;
  if (isOrganization(content.owner_type)) path += '/organization';
  if (content.list_id) path += `/lists/${content.list_id}`;
  return path;
};
