import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from './types';

export const enqueueSnackbar = alert => ({
  type: ENQUEUE_SNACKBAR,
  alert: {
    key: new Date().getTime() + Math.random(),
    ...alert,
  },
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const enqueueMessage = (message, variant = 'info') => (
  enqueueSnackbar({
    message,
    options: {
      variant,
    },
  })
);
