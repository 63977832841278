import produce from 'immer';

import {
  CREATE_LIST_REQUEST,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_FAILURE,
  UPDATE_LIST_REQUEST,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_FAILURE,
  DELETE_LIST_REQUEST,
  DELETE_LIST_SUCCESS,
  DELETE_LIST_FAILURE,
} from '../actions/types';
import { isGroup } from '../utils/listOwnerTypes';

const createListRequest = state => produce(state, (draft) => {
  draft.loading = true;
  draft.error = null;
});

const createListSuccess = (state, action) => produce(state, (draft) => {
  const list = action.payload;
  if (isGroup(list.owner_type)) {
    const groupIndex = state.data.findIndex(x => x.id.toString() === list.owner_id.toString());
    draft.data[groupIndex].lists = [...draft.data[groupIndex].lists, list];
  } else { // User and Organization
    draft.data.lists = [...draft.data.lists, list];
  }

  draft.loading = false;
});

const createListFailure = (state, action) => produce(state, (draft) => {
  const { error } = action.payload;
  draft.loading = false;
  draft.error = error;
});

// Update List
const updateListRequest = state => produce(state, (draft) => {
  draft.loading = true;
  draft.error = null;
});

const updateListSuccess = (state, action) => produce(state, (draft) => {
  const list = action.payload;
  if (isGroup(list.owner_type)) {
    const groupIndex = state.data.findIndex(x => x.id.toString() === list.owner_id.toString());
    const listIndex = state.data[groupIndex].lists.findIndex(x => x.id.toString() === list.id.toString());

    draft.data[groupIndex].lists[listIndex] = list;
  } else { // User and Organization
    const listIndex = state.data.lists.findIndex(x => x.id.toString() === list.id.toString());
    draft.data.lists[listIndex] = list;
  }

  draft.loading = false;
});

const updateListFailure = (state, action) => produce(state, (draft) => {
  const { error } = action.payload;
  draft.loading = false;
  draft.error = error;
});

// DELETE List
const deleteListRequest = state => produce(state, (draft) => {
  draft.loading = true;
  draft.error = null;
});

const deleteListSuccess = (state, action) => produce(state, (draft) => {
  const list = action.payload;
  if (isGroup(list.owner_type)) {
    const groupIndex = state.data.findIndex(x => x.id.toString() === list.owner_id.toString());
    const listIndex = state.data[groupIndex].lists.findIndex(x => x.id.toString() === list.id.toString());

    draft.data[groupIndex].lists.splice(listIndex, 1);
  } else {
    const listIndex = state.data.lists.findIndex(x => x.id.toString() === list.id.toString());
    draft.data.lists.splice(listIndex, 1);
  }

  draft.loading = false;
});

const deleteListFailure = (state, action) => produce(state, (draft) => {
  const { error } = action.payload;
  draft.loading = false;
  draft.error = error;
});

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_LIST_REQUEST:
      return createListRequest(state);
    case CREATE_LIST_SUCCESS:
      return createListSuccess(state, action);
    case CREATE_LIST_FAILURE:
      return createListFailure(state, action);
    case UPDATE_LIST_REQUEST:
      return updateListRequest(state);
    case UPDATE_LIST_SUCCESS:
      return updateListSuccess(state, action);
    case UPDATE_LIST_FAILURE:
      return updateListFailure(state, action);
    case DELETE_LIST_REQUEST:
      return deleteListRequest(state);
    case DELETE_LIST_SUCCESS:
      return deleteListSuccess(state, action);
    case DELETE_LIST_FAILURE:
      return deleteListFailure(state, action);
    default:
      return state;
  }
};
