import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import {
  List, ListItem, Checkbox, TextField, InputAdornment, Grid,
} from '@material-ui/core';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import MixpanelClient from '../../../utils/mixpanel/client';
import { MIXPANEL_FILTERED_ON_TAGS } from '../../../utils/mixpanel/events';

const CheckBoxItem = ({ item, refine, onChange }) => (
  <ListItem
    key={item}
    style={{ padding: 0 }}
    onClick={(e) => {
      e.preventDefault();
      MixpanelClient.track(MIXPANEL_FILTERED_ON_TAGS);
      onChange();
      refine(item.value);
    }}
  >
    <Checkbox
      checked={item.isRefined}
      disableRipple
      color="primary"
    />
    <div className="m-1 text-second badge badge-neutral-second nav-link">{item.label}</div>
    <div className="badge badge-neutral-dark badge-pill m-1 text-dark">{item.count}</div>
  </ListItem>
);

const MaterialUiCheckBoxRefinementList = ({
  items,
  attribute,
  refine,
  createURL,
  searchForItems,
  onChange,
}) => (
  <Grid item className="bg-white ml-2 mr-2 mt-2">
    <div className="search-wrapper">
      <TextField
        variant="outlined"
        size="small"
        className="bg-white ml-2 mr-2 mt-2"
        classes={{ root: 'input-border-0' }}
        id="search-tag-input"
        placeholder="Search for Tags..."
        onChange={event => searchForItems(event.currentTarget.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchTwoToneIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
    <div className="scroll-area-lg" style={{ height: 'auto' }}>
      <List dense>
        {items.map(item => (
          <CheckBoxItem
            key={item.label}
            item={item}
            refine={refine}
            createURL={createURL}
            onChange={onChange}
          />
        ))}
      </List>
    </div>
  </Grid>
);

export default connectRefinementList(MaterialUiCheckBoxRefinementList);
