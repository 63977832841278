import {
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
} from '../actions/types';


const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return [
        ...state,
        {
          ...action.alert,
        },
      ];

    case REMOVE_SNACKBAR:
      return state.filter(alert => alert.key !== action.key);
    default:
      return state;
  }
};
