import mixpanel from 'mixpanel-browser';

const envProduction = process.env.NODE_ENV === 'production';
const trackDev = false;

if (envProduction) {
  mixpanel.init('9425613f46b4f93c1de7f2a5edb940a8');
} else {
  mixpanel.init('c35a3ff505c8936974d306956f207d00');
}

const actions = {
  identify: (id) => {
    if (envProduction || trackDev) mixpanel.identify(id);
  },
  alias: (id) => {
    if (envProduction || trackDev) mixpanel.alias(id);
  },
  track: (name, props = {}) => {
    if (envProduction || trackDev) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (envProduction || trackDev) mixpanel.people.set(props);
    },
  },
};

export default actions;
