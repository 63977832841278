import {
  FETCH_PAYMENT_METHODS_REQUEST,
  FETCH_PAYMENT_METHODS_FAILURE,
  FETCH_PAYMENT_METHODS_SUCCESS,
} from '../actions/types';

const initialState = { data: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        data: [],
        error: undefined,
        loading: true,
      };
    case FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_PAYMENT_METHODS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        data: [],
        error,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export const getPaymentMethods = state => state.PaymentMethods.data;
