export const ALGOLIA_APPLICATION_ID = 'R29PWPC6J4';

export const ISELO_API_HOST_DEV = 'http://dev-iseloapp.com:3000';
export const MIRCOSOFT_ONE_DRIVE_FILE_PICKER_REDIRECT_URI_DEV = 'http://localhost:3001/file-picker-redirect.html'
export const ALGOLIA_CONTENTS_INDEX_DEV = 'iselo-dev-contents';

export const ISELO_API_HOST_PROD = 'https://api.iseloapp.com';
export const ALGOLIA_CONTENTS_INDEX_PROD = 'iselo-prod-contents';
export const MIRCOSOFT_ONE_DRIVE_FILE_PICKER_REDIRECT_URI_PROD = 'https://web.iseloapp.com/file-picker-redirect.html'

export const MICROSOFT_GRAPH_CLIENT_ID = '7e30db82-d219-4339-8e49-e8aea723421d';

export const USER = 'User';
export const GROUP = 'Group';
export const ORGANIZATION = 'Organization';
