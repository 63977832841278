// MixPanel Events
export const MIXPANEL_ADDED_CONTENT = 'Added Content';
export const MIXPANEL_UPDATED_CONTENT = 'Updated Content';
export const MIXPANEL_REMOVED_CONTENT = 'Removed Content';

export const MIXPANEL_ADDED_COMMENT = 'Added Comment ';
export const MIXPANEL_UPDATED_COMMENT = 'Updated Comment';
export const MIXPANEL_REMOVED_COMMENT = 'Removed Comment';

export const MIXPANEL_ADDED_GROUP = 'Added Group';
export const MIXPANEL_UPDATED_GROUP = 'Updated Group';
export const MIXPANEL_REMOVED_GROUP = 'Removed Group';

export const MIXPANEL_ADDED_GROUP_MEMBER = 'Added Group Member';
export const MIXPANEL_UPDATED_GROUP_MEMBER = 'Updated Group Member';
export const MIXPANEL_REMOVED_GROUP_MEMBER = 'Removed Group Member';

export const MIXPANEL_ADDED_LIST = 'Added List ';
export const MIXPANEL_UPDATED_LIST = 'Updated List';
export const MIXPANEL_REMOVED_LIST = 'Removed List';

export const MIXPANEL_FILTERED_ON_TAGS = 'Filtered on Tags';
export const MIXPANEL_CLICKED_ON_SHOW_ALL = 'Clicked on Show All';

// Header Actions
export const MIXPANEL_CLICKED_ON_ADD_LINK = 'Clicked on Add Link';
export const MIXPANEL_CLICKED_ON_ADD_MEMO = 'Clicked on Add Memo';
export const MIXPANEL_CLICKED_ON_ADD_FILE = 'Clicked on Add File';
export const MIXPANEL_CLICKED_ON_ADD_LIST = 'Clicked on Add List';

// Content Card Actions
export const MIXPANEL_CLICKED_ON_MARK_COMPLETED = 'Clicked on Mark Completed';
export const MIXPANEL_CLICKED_ON_EDIT = 'Clicked on Edit Content';
export const MIXPANEL_CLICKED_ON_COMMENTS = 'Clicked on Comments';
export const MIXPANEL_CLICKED_ON_DELETE = 'Clicked on Delete Content';
