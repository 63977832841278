import React from 'react';
import { connect } from 'react-redux';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MixpanelClient from '../../utils/mixpanel/client';
import { MIXPANEL_CLICKED_ON_EDIT } from '../../utils/mixpanel/events';
import { getCurrentUser } from '../../reducers/CurrentUser';

const ContentEditButton = ({
  currentUser, content, iconOnly = false,
}) => {
  if (!currentUser) return null;
  const trackEditClick = () => MixpanelClient.track(MIXPANEL_CLICKED_ON_EDIT, { content_title: content.title });
  const isCurrentUserContent = () => (content.creator && Number(content.creator.id) === Number(currentUser.id));

  return isCurrentUserContent() && (
    <Tooltip title="Edit" enterDelay={1000}>
      {iconOnly
        ? (
          <IconButton
            className="m-2"
            color="primary"
            aria-label="edit"
            size="small"
            component={Link}
            to={`/contents/${content.id}/edit`}
            onClick={trackEditClick}
          >
            <i className="fa fa-pencil-alt" />
          </IconButton>
        )
        : (
          <Button
            className="m-2"
            color="primary"
            aria-label="edit"
            component={Link}
            to={`/contents/${content.id}/edit`}
            onClick={trackEditClick}
          >
            <span>
              <i className="fa fa-pencil-alt" />
              {' '}
              Edit
            </span>
          </Button>
        )}
    </Tooltip>
  );
};

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps, null)(ContentEditButton);
